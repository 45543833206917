*{
  padding: 0;
  margin: 0;
  font: inherit;
}
a{
  text-decoration: none;
  color: inherit;
}
body{
  font-family: $primary-font;
  background-color: #000;
}
.swiper-pagination-bullet {
  background: #fff;
}