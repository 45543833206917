//  Mixins for text sizes
$text-sizes: xxs, xs, sm, md, lg, xl, 2xl, 3xl, 4xl, 5xl, 6xl, 7xl, 8xl, 9xl;
@mixin TextSizes {
  @each $text-size in $text-sizes {
    .text-#{$text-size} {
      font-size: var(--text-#{$text-size}) !important;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    @each $text-size in $text-sizes {
      .text-sm-#{$text-size} {
        font-size: var(--text-#{$text-size}) !important;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    @each $text-size in $text-sizes {
      .text-md-#{$text-size} {
        font-size: var(--text-#{$text-size}) !important;
      }
    }
  }

  @media (min-width: $breakpoint-lg) {
    @each $text-size in $text-sizes {
      .text-lg-#{$text-size} {
        font-size: var(--text-#{$text-size}) !important;
      }
    }
  }

  @media (min-width: $breakpoint-xl) {
    @each $text-size in $text-sizes {
      .text-xl-#{$text-size} {
        font-size: var(--text-#{$text-size}) !important;
      }
    }
  }
}

$font-weights: thin, extralight, light, normal, medium, semibold, bold, extrabold, black;

@mixin FontWeights() {
  @each $font-weight in $font-weights {
    .font-#{$font-weight}, .#{$font-weight} {
      font-weight: var(--font-#{$font-weight}) !important;
    }
  }
}

@include TextSizes;
@include FontWeights;
