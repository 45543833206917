
.color{
  &-{
    &dark{
      color: #000;
    }
    &light{

    }
  }
}
.background{
  &-{
    &dark{
      background-color: #121212;
    }
  }
}

.mk-button {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.mk-button::after,
.mk-button::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.mk-button::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 10px;
}

.mk-button::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.mk-button:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.mk-button:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.mk-button:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
.mk-card{
	background-color: rgba(255, 255, 255, 0.06);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	-webkit-box-shadow: 0 25px 23px rgba(0, 0, 0, 0.15);
	box-shadow: 0 25px 23px rgba(0, 0, 0, 0.15);
	border: 1.5px solid rgba(255, 255, 255, 0.06);
  border-radius: 20px;
  @media(min-width:998px){
    border-radius: 35px;   
  }
}

.mk-circular-btn {
  width:50px;
  height: 50px;
  background-color: #000;
  cursor: pointer;
  position:absolute;
  top:2%;
  right:2%;
}

.opacity-5{
  opacity: 0.5;
}