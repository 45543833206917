.mk-p__hero {
  height: 568px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  &::before {
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &- {
    &container {
      z-index: 2;
    }
  }
}

// Tech stack

.mk-techstack{
  &__{
    &item{
      height: 100px;
      width: 100px;
      @media(max-width:768px){
        height: 60px;
        width: 60px;
      }
      &-{
        &img{
          object-fit: contain;
          width: 100%;
          height: 100%;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
        }
      }
    }
  }
}