$primary-font: "Roboto", sans-serif;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

:root {
  // text-sizes
  --text-base: 0.25rem;
  --text-xxs: 10px;
  --text-xs: calc(var(--text-base) * 3);
  --text-sm: 14px;
  --text-md: calc(var(--text-base) * 4);
  --text-lg: 18px;
  --text-xl: calc(var(--text-base) * 5);
  --text-2xl: calc(var(--text-base) * 6);
  --text-3xl: calc(var(--text-base) * 7);
  --text-4xl: calc(var(--text-base) * 8);
  --text-5xl: calc(var(--text-base) * 9);
  --text-6xl: calc(var(--text-base) * 12);
  --text-7xl: calc(var(--text-base) * 13);
  --text-8xl: calc(var(--text-base) * 14);
  --text-9xl: calc(var(--text-base) * 15);
  --text-10xl: 100px;
  --text-abnormal: 128px;

  // Font weight
  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300; 
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --font-black: 900;
}
