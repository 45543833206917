// Navbar : Start
.mk-nav {
  height: 75px;
  z-index: 5;
  background-color: rgba(29, 29, 31, 0.72);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  &- {
    &logo {
      width: 100px;
    }
  }
}
// Navbar : End

// Hero
.mk-hero {
  &- {
    &thumbnail {
      &--project {
        height: 350px;
        @media (min-width: 998px) {
          height: 450px;
        }
      }
      img {
        object-fit: cover;
      }
    }
  }
}

.mk-footer{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}